<template>
  <div>
    <div class="filter-container">
      <el-form inline>
        <el-form-item>
          <el-input v-model="query.title" placeholder="请输入标题" clearable />
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.status" placeholder="请选择状态" clearable>
            <el-option v-for="(value, key) in status" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="title"
        label="标题"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ status[row.status] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        label="操作"
        align="center"
        width=""
      >
        <template slot-scope="{ row }">
          <el-row>
            <el-button size="small" type="primary" @click="edit(row.id)">编辑</el-button>
            <el-button size="small" type="danger" @click="deleteUnion(row.id)">删除</el-button>
          </el-row>
          <el-row style="margin-top:10px;">
            <el-button v-if="row.status" size="small" type="danger" @click="disable(row.id)">禁用</el-button>
            <el-button v-else type="success" size="small" @click="enable(row.id)">启用</el-button>
            <el-button type="success" size="small" @click="recommend(row.id)">推荐</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="currentChange"
      />
    </div>
    <Form ref="form" :method="method" />
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/union'
import Form from './form'
export default {
  components: { Form },
  mixins: [list],
  data() {
    return {
      api,
      status: ['禁用', '启用']
    }
  },
  computed: {
    method() {
      return this.$route.path
    }
  },
  watch: {
    method() {
      if (this.method.includes('/dynamic')) {
        return this.getList()
      }
      if (this.method.includes('/resources')) {
        return this.getResourcesList()
      }
    }
  },
  created() {
    if (this.method.includes('/dynamic')) {
      return this.getList()
    }
    if (this.method.includes('/resources')) {
      return this.getResourcesList()
    }
  },
  methods: {
    search() {
      this.page = 1
      if (this.method.includes('/dynamic')) {
        return this.getList()
      }
      if (this.method.includes('/resources')) {
        return this.getResourcesList()
      }
    },
    refresh() {
      this.query = {}
      this.page = 1
      this.queryTime = {}
      if (this.method.includes('/dynamic')) {
        return this.getList()
      }
      if (this.method.includes('/resources')) {
        return this.getResourcesList()
      }
    },
    deleteUnion(id) {
      if (this.method.includes('/dynamic')) {
        this.$confirm('确定要删除吗？', '提示')
          .then(() => {
            this.api.del(id)
              .then(() => {
                this.$message.success('删除成功')
              })
            this.getList()
          })
      }
      if (this.method.includes('/resources')) {
        this.$confirm('确定要删除吗？', '提示')
          .then(() => {
            this.api.del(id)
              .then(() => {
                this.$message.success('删除成功')
              })
            this.getResourcesList()
          })
      }
    },
    enable(id) {
      this.api.enable({ id }).then(() => {
        this.$message({
          message: '启用成功',
          type: 'success'
        })
        if (this.method.includes('/dynamic')) {
          return this.getList()
        }
        if (this.method.includes('/resources')) {
          return this.getResourcesList()
        }
      })
    },
    disable(id) {
      this.api.disable({ id }).then(() => {
        this.$message({
          message: '禁用成功',
          type: 'success'
        })
        if (this.method.includes('/dynamic')) {
          return this.getList()
        }
        if (this.method.includes('/resources')) {
          return this.getResourcesList()
        }
      })
    },
    recommend(id) {
      this.api.recommend(id).then(() => {
        this.$message({
          message: '推荐成功',
          type: 'success'
        })
        if (this.method.includes('/dynamic')) {
          return this.getList()
        }
        if (this.method.includes('/resources')) {
          return this.getResourcesList()
        }
      })
    },
    getResourcesList() {
      this.loading = true
      this.api.getResourcesList({ ...this.query, page: this.page, pageSize: this.pageSize })
        .then(res => {
          const { list = [], count = 0 } = res.data
          this.list = list
          this.count = count
        })
        .finally(() => {
          this.loading = false
        })
    },
    resources() {
      this.query = {}
      this.page = 1
      this.getResourcesList()
    },
    currentChange(val) {
      this.page = val
      if (this.method.includes('/dynamic')) {
        return this.getList()
      }
      if (this.method.includes('/resources')) {
        return this.getResourcesList()
      }
    }
  }
}
</script>
