import request from '@/utils/axios'

/** 获取联盟动态列表 */
export function getList(params) {
  return request({
    url: '/union/dynamic',
    method: 'get',
    params
  })
}

/** 获取联盟资源列表 */
export function getResourcesList(params) {
  return request({
    url: '/union/resource',
    method: 'get',
    params
  })
}

/** 获取联盟资源添加 */
export function addResource(data) {
  return request({
    url: '/union/resource',
    method: 'post',
    data
  })
}

/** 详情 */
export function getDetail(id) {
  return request({
    url: `/union/detail?id=${id}`,
    method: 'get'
  })
}

/** 添加 */
export function add(data) {
  return request({
    url: '/union/dynamic',
    method: 'post',
    data
  })
}

/** 编辑 */
export function edit(data) {
  return request({
    url: '/union',
    method: 'put',
    data
  })
}

/** 删除 */
export function del(id) {
  return request({
    url: `/union?id=${id}`,
    method: 'delete'
  })
}

// 启用
export function enable(params) {
  return request({
    url: '/union/enable',
    method: 'get',
    params
  })
}

// 禁用
export function disable(params) {
  return request({
    url: '/union/disable',
    method: 'get',
    params
  })
}

// 推荐
export function recommend(id) {
  return request({
    url: `/union/recommend?id=${id}`,
    method: 'put'
  })
}

