<template>
  <el-dialog
    :title="`${id ? '编辑' : '新增'}${method.includes('/dynamic') ? '联盟动态' : '价值资源'}`"
    :visible.sync="show"
    width="600px"
  >
    <el-form v-if="show" :model="form" label-width="auto">
      <el-form-item label="标题">
        <el-input v-model="form.title" />
      </el-form-item>
      <el-form-item label="链接">
        <el-input v-model="form.coverUrl" />
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="end">
      <el-button @click="cancel">取消</el-button>
      <el-button v-if="method.includes('/dynamic')" type="primary" @click="submit">确定</el-button>
      <el-button v-else type="primary" @click="onSubmit">确定</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import form from '@/mixins/form'
import * as api from '@/api/union'

export default {
  mixins: [form],
  props: {
    method: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      api,
      show: false
    }
  },
  methods: {
    onSubmit() {
      if (this.id) {
        this.api.edit(this.form)
          .then(() => {
            this.$message.success('修改成功')
            this.$parent.resources()
            this.back()
          })
      } else {
        this.api.addResource(this.form)
          .then(() => {
            this.$message.success('新增成功')
            this.$parent.resources()
            this.back()
          })
      }
    }
  }
}
</script>
